*::selection {
  color: white;
  background-color: black;
}

* {
  -webkit-tap-highlight-color: transparent;
}

img {
  display: block;
  width: 100%;
}

input {
  border: 1px solid black;
  padding: 10px;
  margin-bottom: $grid-gutter / 3;
  border-radius: 0;
  -webkit-appearance: none;
}

.button, .button--primary, .shopify-buy__btn {
  position: relative;
  padding: 10px 15px;
  color: white;
  border: 1px black solid;
  outline: 0;
  overflow: hidden;
  background: black;
  z-index: 1;
  cursor: pointer;
  transition: 0.08s ease-in;
  text-transform: uppercase;
  font-size: 0.9em;
}

.button--disabled {
  background-color: #fff;
  border-color: #000;
  color: #000;
}

.button--primary:hover, .shopify-buy__btn:hover {
  color: black;
}

.button--primary:before, .shopify-buy__btn:before {
  content: "";
  position: absolute;
  background: white;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  transition: top 0.09s ease-in;
}

.button--primary:hover:before, .shopify-buy__btn:hover:before {
  top: 0;
}

.not-found {
  text-align: center;
}

.divider {
  display: block;
  height: 2px;
  border-top: 2px dashed #ddd;
  margin: 0 15px;

  &.hidden--medium-up {
    display: none;
    @media (max-width: 762px) {
      display: block;
    }
  }
}

.holding__wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;

  .holding__logo {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      margin-bottom: $grid-gutter / 2;
      margin-left: -10px;
    }
  }
}