.hero-image {
  img {
    margin: 0 auto;
    max-height: 80vh;
    max-width: 100%;
    width: auto;
  }
}

.page-title {
  margin: 2em 0;

  @media (max-width: 600px) {
    margin: 1.5em 0 1em;
  }

  h1 {
    margin-bottom: 5px;
  }
}

.post-description {
  margin-bottom: $grid-gutter;

  @media (max-width: 762px) {
    margin-bottom: $grid-gutter / 2;
  }
}

.description {
  display: flex;
  flex-direction: row;
  margin-bottom: $grid-gutter;

  @media (max-width: 762px) {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .description__paragraph {
    padding-right: $grid-gutter * 2;
    flex: 1 1 75%;
    margin-bottom: $grid-gutter / 2;

    @media (max-width: 762px) {
      padding-right: 0;
      order: 2;
    }
  }

  .description__list {
    flex: 1 1 500px;

    .button--disabled:hover {
      cursor: not-allowed;
    }

    .description__list--details {
      margin-bottom: 1em;
    }
    ul li {
      padding-bottom: 0.25em;
      line-height: 1.25;
      font-family: $mono-reg;

      strong {
        font-family: $mono-bold;
      }
    }
    @media (max-width: 762px) {
      order: 1;
    }
  }
  .description__list--shipping {
    margin-top: $grid-gutter;
  }

  .divider {
    margin: 25px 0;
  }
}

.gallery-wrapper {
  width: 100%;
  @media (max-width: 762px) {
    order: 3;
  }

  img {
    margin-bottom: $grid-gutter * 2;
    max-height: 80vh;
    width: auto;
    max-width: 100%;
    margin: 0 auto $grid-gutter;

    @media (max-width: 600px) {
      margin-bottom: $grid-gutter;
      max-height: auto;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}