// Mono
@font-face {
  font-family: "plex-mono-reg";
  src: url("../fonts/IBMPlexMono-Regular.woff2") format("woff2"),
  url("../fonts/IBMPlexMono-Text.woff") format("woff");
}

@font-face {
  font-family: "plex-mono-ital";
  src: url("../fonts/IBMPlexMono-Italic.woff2") format("woff2"),
  url("../fonts/IBMPlexMono-Italic.woff") format("woff");
}

@font-face {
  font-family: "plex-mono-bold";
  src: url("../fonts/IBMPlexMono-SemiBold.woff2") format("woff2"),
  url("../fonts/IBMPlexMono-SemiBold.woff") format("woff");
}

// Sans
@font-face {
  font-family: "plex-sans-reg";
  src: url("../fonts/IBMPlexSans-Regular.woff2") format("woff2"),
  url("../fonts/IBMPlexSans-Text.woff") format("woff");
}

@font-face {
  font-family: "plex-sans-ital";
  src: url("../fonts/IBMPlexSans-Italic.woff2") format("woff2"),
  url("../fonts/IBMPlexSans-Italic.woff") format("woff");
}

@font-face {
  font-family: "plex-sans-bold";
  src: url("../fonts/IBMPlexSans-SemiBold.woff2") format("woff2"),
  url("../fonts/IBMPlexSans-SemiBold.woff") format("woff");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading;
}

h1 {
  font-size: 3.125em;

  @media (max-width: 992px) {
    font-size: 2.618em;
  }
  @media (max-width: 600px) {
    font-size: 1.931em;
  }
}

h2 {
  font-size: 2.618em;
  @media (max-width: 992px) {
    font-size: 1.931em;
  }
}

h3 {
  font-size: 1.931em;
  @media (max-width: 992px) {
    font-size: 1.618em;
  }
}

h4 {
  font-size: 1.618em;
}

h5 {
  font-size: 1.194em;
}

h6 {
  font-size: 1em;
}

p,
input,
select,
ul,
li {
  font-family: $font-body;
}

label,
span,
button {
  font-family: $mono-reg;
}

p {
  font-size: 1.2em;
  padding-bottom: 1em;
  line-height: 1.4;
  @media (max-width: 992px) {
    font-size: 1.1em;
  }
}

.description__paragraph, .page-content.default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-bold;
  }

  ul {
    margin-bottom: $grid-gutter;

    li {
      padding-bottom: 5px;
      list-style: circle;
      margin-left: 19px;
      font-size: 1.2em;
      line-height: 1.4;
      @media (max-width: 992px) {
        font-size: 1.1em;
        line-height: 1.1;
        padding-bottom: 8px;
      }
    }
  }

  hr {
    display: block;
    height: 2px;
    border: none;
    border-top: 2px dashed #ddd;
    margin: $grid-gutter / 2 0 $grid-gutter / 1.2;
    background: none;
  }
}

.page-content.default {
  h2 {
  font-size: 1.618em;
  margin-bottom: 10px;
  }

  h3 {
    font-size: 1.25em;
    margin-bottom: 5px;
  }
}

strong {
  font-family: $font-bold;
}

em {
  font-family: $font-ital;
}

a {
  color: black;
}

.product__details--title h4,
.product__details--title h5,
.featured-product__details h1,
.page-title h1,
.page-title h3 {
  font-family: $font-bold;
}