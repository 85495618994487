.default {
  margin-bottom: 0;

  .gallery-wrapper {
    margin-top: $grid-gutter;
    margin-bottom: $grid-gutter;
  }

  .hero-image {
    margin-bottom: $grid-gutter * 2;

    @media (max-width: 680px) {
      margin-bottom: $grid-gutter;
    }
  }

  .post-description {
    max-width: 850px;
    margin: 0 auto;
  }

  h1 {
    margin-bottom: $grid-gutter / 1.5;
  }

  h3 {
    margin-top: $grid-gutter / 2;
    margin-bottom: $grid-gutter / 2;
  }
}