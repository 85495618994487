/*==============================================================================
HEADER
==============================================================================*/
header {
  margin: 2em 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    margin: 1em $grid-gutter / 2 0.5em;
  }

  .logo {
    flex: 1 1 10%;
    @media (max-width: 600px) {
      margin-top: 3px;
    }
    svg {
      height: 2.5em;
      width: 56px;
      @media (max-width: 600px) {
        height: 1.5em;
        width: 3em;
        margin-left: -$grid-gutter / 3;
      }
    }
  }
  .store-title {
    flex: 1 1 80%;
    text-align: center;
    margin-left: -5px;
    padding-top: 4px;

    @media (max-width: 600px) {
      text-align: center;
      padding-top: 0;
    }
  }

  .coming-soon {
    text-align: right;
    flex: 1 1 10%;

    @media (max-width: 600px) {
      span {
        display: none;
      }
    }
  }

  a {
    text-decoration: none!important;
  }
}

/*==============================================================================
MAIN
==============================================================================*/

main {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: $grid-gutter;

  .page-width {
    max-width: 1080px;
    padding: $grid-gutter;

    @media (max-width: 600px) {
      padding: $grid-gutter / 2;
    }
  }
}
/*==============================================================================
FOOTER
==============================================================================*/
footer {
  text-align: center;
  padding: $grid-gutter 0;

  .newsletter {
    h5 {
      margin-bottom: $grid-gutter / 2;
    }
  }
}