.product-feed {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .featured-product {
    display: flex;
    margin-bottom: $grid-gutter * 2;

    @media (max-width: 762px) {
      flex-wrap: wrap;
    }
    @media (max-width: 600px) {
      margin-bottom: $grid-gutter * 1.5;
    }

    .featured-product__details {
      margin: $grid-gutter / 2;

      @media (max-width: 762px) {
        flex: 1 1 100%;
        order: 2;
      }
      @media (max-width: 600px) {
        margin: $grid-gutter / 1.5 0 0;
      }

      button {
        margin-top: 2em;
        @media (max-width: 600px) {
          margin-top: 1.5em;
        }
      }

      h1 {
        margin-bottom: 5px;
      }
      h3 {
        margin-bottom: $grid-gutter / 2;
      }
      p {
        margin-top: $grid-gutter / 2;

        @media (max-width: 762px) {
          margin-top: 0;
        }
      }
    }
    .featured-product__image {
      flex: 1 0 50%;
      position: relative;
      margin-right: $grid-gutter / 2;
      text-align: right;

      @media (max-width: 762px) {
        flex: 1 1 100%;
        order: 1;
        margin: 15px;
      }
      @media (max-width: 600px) {
        margin: 0;
      }

      .featured-product__image--label {
        position: absolute;
        top: -9px;
        right: -20px;
        transform: rotate(90deg);
        width: 0px;
        @media (max-width: 600px) {
          display: none;
        }
      }

      img {
        outline: 3px solid white;
        max-height: 400px;
        width: auto;
        max-width: 100%;
        display: inline-block;

        @media (max-width: 762px) {
          max-height: 100%;
        }

        &:hover {
          outline: 3px dashed black;
        }
      }
    }
  }

  & > .product {
    box-sizing: border-box;
    margin: 0 15px;
    width: calc(1/3*100% - (1 - 1/3)*45px);

    @media (max-width: 992px) {
      width: calc(1/2*100% - (1 - 1/2)*60px);
    }
    @media (max-width: 600px) {
      width: 100%;
    }
    @media (max-width: 600px) {
      margin: 0;
    }
  }

  .product {
    margin-bottom: $grid-gutter * 1.5;

    &:last-of-type {
      margin-bottom: 0;
    }

    .product__details {
      margin: $grid-gutter / 2 0 $grid-gutter / 1.25;
      display: flex;
      justify-content: space-between;

      .product__details--title {
        h4 {
          padding-bottom: $grid-gutter / 5;
          line-height: 1.1;
        }
      }
      .product__details--price {
        padding-top: 4px;
      }
    }
    .product__image {
      img {
        outline: 3px solid white;

        &:hover {
          outline: 3px dashed black;
        }
      }
    }

    .product__badge {
      font-family: $mono-reg;
      background-color: black;
      padding: 6px 12px;
      color: white;
      text-transform: uppercase;
      font-size: 13px;
      position: absolute;
      line-height: 1;
    }
  }
}