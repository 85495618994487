.resources {
  margin-bottom: 0;

  .gallery-wrapper {
    margin-top: $grid-gutter;
    margin-bottom: $grid-gutter;
  }

  .hero-image {
    margin-bottom: $grid-gutter * 2;

    @media (max-width: 680px) {
      margin-bottom: $grid-gutter;
    }
  }

  .post-description {
    max-width: 850px;
    margin: 0 auto;
  }

  h1 {
    margin-bottom: $grid-gutter / 1.5;
  }

  h3 {
    margin-top: $grid-gutter / 2;
    margin-bottom: $grid-gutter / 2;
  }
}

.description__paragraph, .page-content.resources {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-bold;
  }

  ul {
    margin-bottom: $grid-gutter;

    li {
      padding-bottom: 5px;
      list-style: circle;
      margin-left: 19px;
      font-size: 1.2em;
      line-height: 1.4;
      @media (max-width: 992px) {
        font-size: 1.1em;
        line-height: 1.1;
        padding-bottom: 8px;
      }
    }
  }

  hr {
    display: block;
    height: 2px;
    border: none;
    border-top: 2px dashed #ddd;
    margin: $grid-gutter / 1.2 0;
    background: none;
  }
}

.page-content.resources {
  h1 {
    font-size: 2.618em;
    margin: $grid-gutter * 1.5 0 $grid-gutter / 3;

    @media (max-width: 992px) {
      font-size: 1.931em;
    }
  }

  h2 {
    position: relative;
    font-size: 1.931em;
    padding: $grid-gutter 0 $grid-gutter / 3;
    margin-bottom: $grid-gutter;

    @media (max-width: 992px) {
      font-size: 1.618em;
    }

    &::after {
      position: absolute;
      bottom: 0;
      content: '';
      width: 10px;
      height: 2px;
      background-color: #ddd;
      display: block;
    }
  }

  h3 {
    font-size: 1.618em;
    margin-bottom: 5px;

    @media (max-width: 992px) {
      font-size: 1.2em;
    }
  }
}