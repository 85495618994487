.filter-wrapper {
  margin: 0 $grid-gutter / 2 $grid-gutter;

  @media (max-width: 600px) {
    margin: 0 0 $grid-gutter / 1.5;
  }

  p {
    padding-bottom: $grid-gutter / 3;

    strong {
      font-family: $mono-bold;
    }
  }
}

.news {
  .product__details--title {
    p {
      padding-bottom: 0;
    }
  }
}