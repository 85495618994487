// Mobile Menu
.hamburger {
  flex: 1 0 10%;
  text-align: right;
  display: block;
  margin-right: -5px;
  margin-top: 4px;

  svg {
    max-width: 45px;

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 600px) {
    margin-top: 5px;

    svg {
      max-width: 36px;
    }
  }
}

.mobile-menu {
  width: 0px;
  transition: 0.5s width linear;
  display: block;
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 666;
  top: 0;
  right: 0;
  background-color: black;
  overflow-x: hidden;
  transition: 0.3s width ease;

  .mobile-menu--close {
    position: absolute;
    top: 40px;
    right: 25px;
    z-index: 66666;
    width: 40px;

    @media (max-width: 600px) {
      top: 20px;
      right: 8px;
    }

    &:hover {
      cursor: pointer;
    }
    svg {
      fill: white;
    }
  }

  &.show {
    width: 250px;
  }

  .first-tier {
    padding: 0 0 5px 20px;
    top: 80px;
    position: relative;
    font-family: $font-bold;
  }

  ul {
    li,
    .dropdown-toggle {
      font-family: $mono-reg;
      padding-bottom: 10px;
      display: block;
      white-space: nowrap;
      color: white;

      a {
        color: white;
        font-size: 18px;
        font-family: $mono-reg;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
